define(['app', 'componentHelper'], (app, componentHelper) => {
  const personalisationSelect = () => {
    const component = {};

    component.config = {
      selectors: {
        withoutEngravingTab: '.personalisation_withoutEngraving_tab',
        withEngravingTab: '.personalisation_withEngraving_tab',
        personalisationTabs: '.personalisation_tab_radio',
        personalisationWrapper: '.personalisation_form_wrapper',
        overlay: '.productImageCarousel_personalisationOverlay',
        priceMessage: '.personalisation_priceMessage',
        personalisationForm: '[data-component=personalisationOverlay]',
        personalisationInputs: '.personalisation_overlayTextInput',
        personalisationText: '.personalisationText',
        zoomButton: '.athenaProductImageCarousel_zoom',
        tabs: '.personalisation_tabs',
        variationDataContainer:
          '[data-variation-container="athenaProductVariations"]',
        invalidVariationMessage: '.personalisation_invalidVariationsMessage',
        overlayData: '.personalisation_overlayData',
        overlayContainer:
          '.athenaProductImageCarousel_personalisationContainer',
        personalisationCostElem: '.personalisation_priceMessage_price',
        variationsContainer: '.athenaProductPage_productVariations',
        personalisationRequired: '[data-personalisation-required="true"]',
        engravingTabChecked: 'input[value="withEngraving"]:checked',
        persDropDownButton: '.personalisation_dropdown_button',
      },
      dataAttributes: {
        variationHasSelection: 'data-has-selection',
        tabSelected: 'data-selected',
        personalisationValid: 'data-personalisation-valid',
        xCoord: 'data-X',
        yCoord: 'data-Y',
        width: 'data-width',
        height: 'data-height',
        parentWidth: 'data-parent-width',
        parentHeight: 'data-parent-height',
        inputNumber: 'data-input-number',
        fontColor: 'data-color',
        hideOverlay: 'data-hide-overlay',
        fontName: 'data-font',
        fontWeight: 'data-font-weight',
      },
      channels: {
        updateImages: 'productMultipleImages/newImage',
        personalisationMessage: 'personalisation/message',
        updateCarouselImageOverlay: 'productCarouselImageOverlay/update',
        resizeOutput: 'engraving/resizeOutput',
        personalisationVariationUpdate: 'personalisation/variation',
        personalisationUpdate: 'personalisationVariation/update',
        updateAddToBasketButton: 'productAddToBasketButton/newButton',
        updatePrice: 'productPrice/newPrice',
      },
      classNames: {
        firstOutputDiv: 'personalisationText1',
        outputDiv: 'personalisationText',
        variationsContainerForError: 'personalisation_variationsContainer',
      },
    };

    component.init = (element) => {
      component.element = element;
      component.withoutEngravingTab = element.querySelector(
        component.config.selectors.withoutEngravingTab
      );
      component.withEngravingTab = element.querySelector(
        component.config.selectors.withEngravingTab
      );
      component.personalisationTabs = element.querySelectorAll(
        component.config.selectors.personalisationTabs
      );
      component.personalisationWrapper = document.querySelector(
        component.config.selectors.personalisationWrapper
      );
      component.personalisationRequired = document.querySelector(
        component.config.selectors.personalisationRequired
      );

      component.updatedProductId = siteObj.productID;
      app.subscribe(
        component.config.channels.updateCarouselImageOverlay,
        component.updateCarousel
      );

      async function personalisationUpdateReady(){
        await new Promise((resolve) => {
          app.subscribe(
            component.config.channels.personalisationVariationUpdate,
            component.variationUpdate
          );
          resolve();
        })
      } 
      app.personalisationUpdateReady = personalisationUpdateReady;

      component.variationAttributeContainer = document.querySelector(
        component.config.selectors.variationDataContainer
      );
      component.isChildProductSelected = component.variationAttributeContainer
        ? component.variationAttributeContainer.getAttribute(
          component.config.dataAttributes.variationHasSelection
        ) === 'true'
        : false;
      component.priceMessage = component.element.querySelector(
        component.config.selectors.priceMessage
      );

      const variationNotValidMessage = component.element.querySelector(
        component.config.selectors.invalidVariationMessage
      );
      const variations = document.querySelector(
        component.config.selectors.variationsContainer
      );
      if (variations) {
        variations.parentNode.insertBefore(
          variationNotValidMessage,
          variations.nextSibling
        );
      }
      component.variationNotValidMessage = document.querySelector(
        component.config.selectors.invalidVariationMessage
      );

      component.addEventListeners();
      component.personalisationRequired && component.persRequired();
      return component;
    };

    component.addEventListeners = () => {
      component.personalisationTabs.forEach((radio) => {
        radio.addEventListener('click', component.radioClicked);
      });
    };
    component.persRequired = () => {
      const engravingTab = component.element.querySelector(component.config.selectors.engravingTabChecked);
      engravingTab.addEventListener('click', component.radioClicked);
      setTimeout(() => {engravingTab.click()}, 500);
    };

    component.radioClicked = (event) => {
      const engravingTab = event.target.value === 'withEngraving';
      engravingTab
        ? component.updatePersonalisationComponent()
        : component.removePersonalisationComponent();

      component.updateCarouselImages();
      const personalisationForm =
        component.personalisationWrapper.querySelector(
          component.config.selectors.personalisationForm
        );

      const isPersonalisationValid =
        personalisationForm &&
        personalisationForm.getAttribute(
          component.config.dataAttributes.personalisationValid
        );

      app.publish(
        component.config.channels.personalisationMessage,
        engravingTab,
        isPersonalisationValid === 'true'
      );
    };

    component.updatePersonalisationComponent = () => {
      component.withoutEngravingTab.setAttribute(
        component.config.dataAttributes.tabSelected,
        'false'
      );
      component.withEngravingTab.setAttribute(
        component.config.dataAttributes.tabSelected,
        'true'
      );

      if (component.isVariationPersonalisable) {
        component.priceMessage && component.updatePersonalisationCost();
        component.hideVariationInvalidMessage();
        app.ajax.get({
          url: `/${component.updatedProductId}/data.personalisation`,
          success: component.successHandler,
          error: component.errorHandler,
        });

        app.publish(
          component.config.channels.updatePrice,
          component.updatedProductId
        );
        app.publish(
          component.config.channels.updateAddToBasketButton,
          component.updatedProductId
        );
      } else if (component.isChildProductSelected) {
        component.showVariationInvalidMessage();
      }
    };

    component.updateCarouselImages = () => {
      const onEngravingTab =
        component.withEngravingTab.getAttribute(
          component.config.dataAttributes.tabSelected
        ) === 'true';
      const personalised = component.isVariationPersonalisable
        ? component.isVariationPersonalisable
        : false;

      app.publish(component.config.channels.updateImages, {
        productId: component.updatedProductId,
        variation: false,
        personalised: onEngravingTab && personalised,
      });

      component.zoomButton = document.querySelector(
        component.config.selectors.zoomButton
      );
      if (onEngravingTab && personalised) {
        component.zoomButton && (component.zoomButton.style.display = 'none');
      } else {
        component.zoomButton && (component.zoomButton.style.display = 'block');
      }
    };

    component.variationUpdate = (productId, isPersonalisableVariation) => {
      component.updatedProductId = productId;

      component.variationAttributeContainer = document.querySelector(
        component.config.selectors.variationDataContainer
      );
      component.isChildProductSelected = component.variationAttributeContainer
        ? component.variationAttributeContainer.getAttribute(
          component.config.dataAttributes.variationHasSelection
        ) === 'true'
        : false;

      component.isVariationPersonalisable =
        isPersonalisableVariation === 'true';
      const onEngravingTab =
        component.withEngravingTab.getAttribute(
          component.config.dataAttributes.tabSelected
        ) === 'true';

      const personalisationForm =
        component.personalisationWrapper.querySelector(
          component.config.selectors.personalisationForm
        );
      const isPersonalisationValid =
        personalisationForm &&
        personalisationForm.getAttribute(
          component.config.dataAttributes.personalisationValid
        );

      if (onEngravingTab) {
        if (component.isVariationPersonalisable) {
          component.updatePersonalisationComponent();
        } else {
          component.hidePersonalisationForm();
          component.showVariationInvalidMessage();
        }
        component.personalisationComponentCalled = false;
      } else {
        component.hideVariationInvalidMessage();
      }

      app.publish(
        component.config.channels.personalisationMessage,
        onEngravingTab,
        isPersonalisationValid === 'true'
      );
    };

    component.updateCarousel = () => {
      setTimeout(() => {
        component.generateOverlays();
        component.updateOverlayPlacement();
        component.updateEngraving();
        const zoomButton = document.querySelector(
          component.config.selectors.zoomButton
        );
        zoomButton && (zoomButton.style.display = 'none');
      }, 50);
    };

    component.generateOverlays = () => {
      const overlayDataDivs = document.querySelectorAll(
        component.config.selectors.overlayData
      );
      const firstOverlay = document.querySelector(
        component.config.selectors.overlay
      );
      const overlayContainer = document.querySelector(
        component.config.selectors.overlayContainer
      );

      overlayDataDivs.forEach((overlayData, index) => {
        index++;

        const output =
          overlayContainer &&
          overlayContainer.querySelector(
            component.config.selectors.personalisationText + index
          );

        if (output == null && firstOverlay !== null) {
          let newOutput = firstOverlay.cloneNode(true);
          let innerDiv = newOutput.querySelector(
            component.config.selectors.personalisationText
          );
          innerDiv.classList.remove(component.config.classNames.firstOutputDiv);
          innerDiv.classList.add(component.config.classNames.outputDiv + index);
          overlayContainer.appendChild(newOutput);
        }
      });
    };

    component.updateOverlayPlacement = () => {
      const overlayDataDivs = document.querySelectorAll(component.config.selectors.overlayData);
      const fontDropDownButton = document.querySelector(component.config.selectors.persDropDownButton);
      let fontName = '';
      let fontWeight = '';
      if(fontDropDownButton)
      {
        fontName=fontDropDownButton.attributes[component.config.dataAttributes.fontName].nodeValue;
        fontWeight=fontDropDownButton.attributes[component.config.dataAttributes.fontWeight].nodeValue;
      }
      overlayDataDivs.forEach((overlayData, index) => {
        index++;
        const X = overlayData.attributes[component.config.dataAttributes.xCoord].nodeValue;
        const Y = overlayData.attributes[component.config.dataAttributes.yCoord].nodeValue;
        const width = overlayData.attributes[component.config.dataAttributes.width].nodeValue;
        const height = overlayData.attributes[component.config.dataAttributes.height].nodeValue;

        const parentWidth = overlayData.attributes[component.config.dataAttributes.parentWidth].nodeValue;
        const parentHeight = overlayData.attributes[component.config.dataAttributes.parentHeight].nodeValue;
        const fontColor = overlayData.attributes[component.config.dataAttributes.fontColor].nodeValue || '#fff';
        const hideOverlay = overlayData.attributes[component.config.dataAttributes.hideOverlay].nodeValue;

        const imageOverlayChild = document.querySelector(
          `${component.config.selectors.personalisationText}${index}`
        );
        if (imageOverlayChild) {
          const imageOverlay = imageOverlayChild.parentElement;

          imageOverlay.style.top = 100 * (Y / parentHeight - 0.5 * (height / parentHeight)) + '%';
          imageOverlay.style.left = 100 * (X / parentWidth - 0.5 * (width / parentWidth)) + '%';

          imageOverlay.style.height = (100 * height) / parentHeight + '%';
          imageOverlay.style.width = (100 * width) / parentWidth + '%';
          imageOverlay.style.display = hideOverlay === 'true' ? 'none' : 'flex';
          imageOverlay.style.alignItems = 'center';
          imageOverlay.style.justifyContent = 'center';
          imageOverlay.style.color = fontColor;
          if (fontName && fontWeight) {
            imageOverlay.style.fontFamily = fontName;
            imageOverlay.style.fontWeight = fontWeight;
          }
        }
      });
    };

    component.removePersonalisationComponent = () => {
      component.withoutEngravingTab.setAttribute(
        component.config.dataAttributes.tabSelected,
        'true'
      );
      component.withEngravingTab.setAttribute(
        component.config.dataAttributes.tabSelected,
        'false'
      );
      component.hidePersonalisationForm();
      app.publish(
        component.config.channels.updatePrice,
        component.updatedProductId
      );
      app.publish(
        component.config.channels.updateAddToBasketButton,
        component.updatedProductId
      );
    };

    component.hidePersonalisationForm = () => {
      component.personalisationWrapper &&
        (component.personalisationWrapper.style.display = 'none');
      component.priceMessage && (component.priceMessage.style.display = 'none');
      component.hideVariationInvalidMessage();
    };

    component.successHandler = (response) => {
      if (response) {
        component.personalisationWrapper = document.querySelector(
          component.config.selectors.personalisationWrapper
        );
        component.personalisationWrapper.innerHTML = response;
        component.personalisationWrapper &&
          (component.personalisationWrapper.style.display = 'block');
        component.personalisationComponentCalled = true;
        componentHelper.reloadAllComponents(component.personalisationWrapper);
        component.updateCarousel();
      } else {
        console.error('ERROR: Personalisation response is empty.');
      }
    };

    component.errorHandler = () => {
      //Add restrictions upon an errored response.
      console.error('ERROR: Could not retrieve personalisation data.');
    };

    component.updateEngraving = () => {
      const personalisationInputs = document.querySelectorAll(
        component.config.selectors.personalisationInputs
      );

      personalisationInputs.forEach((input, index) => {
        const outputIndex = index + 1;
        const output = document.querySelector(
          `${component.config.selectors.personalisationText}${outputIndex}`
        );
        if (output) {
          if (input.value !== '') {
            output.innerText = input.value;
          }
          app.publish(component.config.channels.resizeOutput, outputIndex);
        }
      });
    };

    component.showVariationInvalidMessage = () => {
      component.variationNotValidMessage &&
        (component.variationNotValidMessage.style.display = 'inline-block');
      const variationsContainer = document.querySelector(
        component.config.selectors.variationsContainer
      );
      variationsContainer &&
        variationsContainer.classList.add(
          component.config.classNames.variationsContainerForError
        );
    };

    component.hideVariationInvalidMessage = () => {
      component.variationNotValidMessage &&
        (component.variationNotValidMessage.style.display = 'none');
      const variationsContainer = document.querySelector(
        component.config.selectors.variationsContainer
      );
      variationsContainer &&
        variationsContainer.classList.remove(
          component.config.classNames.variationsContainerForError
        );
    };

    component.updatePersonalisationCost = () => {
      app.ajax.get({
        url: `/${component.updatedProductId}/cost.personalisation`,
        success: component.personalisationCostSuccessHandler,
        error: () => {},
      });
    };

    component.personalisationCostSuccessHandler = (response) => {
      if (response !== null && response !== '') {
        component.personalisationCostElem =
          component.priceMessage.querySelector(
            component.config.selectors.personalisationCostElem
          );
        if (component.personalisationCostElem) {
          component.personalisationCostElem.innerHTML = response.replace(
            /"/g,
            ''
          );
          component.priceMessage.style.display = 'block';
        }
      }
    };

    return component;
  };

  return personalisationSelect;
});
